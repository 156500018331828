<template>
  <div>
    <h2 v-html="$t('search_translation_title')"></h2>
    <v-text-field
      id="search_translation_text_de"
      v-model="searchCardStore.textDe"
      :label="$t('search_translation_text_de')"
      type="text"
      @blur="search"
      @keyup.enter="search"
    />
    <v-text-field
      id="search_translation_text_fr"
      v-model="searchCardStore.textFr"
      :label="$t('search_translation_text_fr')"
      type="text"
      @blur="search"
      @keyup.enter="search"
    />
    <v-text-field
      id="search_translation_text_it"
      v-model="searchCardStore.textIt"
      :label="$t('search_translation_text_it')"
      type="text"
      @blur="search"
      @keyup.enter="search"
    />
    <v-text-field
      id="search_translation_message_key"
      v-model="searchCardStore.messageKey"
      :label="$t('search_translation_message_key')"
      type="text"
      @blur="search"
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { defineComponent } from 'vue'
import { useSearchTranslationStore } from '@/store/SearchTranslationStore'

export default defineComponent({
  name: 'searchTranslation',
  extends: baseSearchVue,
  data() {
    return {}
  },
  beforeMount() {
    this.setSearchCardStore(useSearchTranslationStore(), true)
  }
})
</script>
