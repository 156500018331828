import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchTranslationStore = defineStore('searchTranslationStore', {
  state: () => ({
    textDe: null as string | null,
    textFr: null as string | null,
    textIt: null as string | null,
    messageKey: null as string | null,
    useTerms: true,
    items: [
      { key: 'textDe', operation: ':' },
      { key: 'textFr', operation: ':' },
      { key: 'textIt', operation: ':' },
      { key: 'messageKey', operation: ':' }
    ] as TermItemDefinition[]
  })
})
